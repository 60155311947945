@tailwind base;
@tailwind components;
@tailwind utilities;

/* Oculta los filtros de Syncfusion   */
/* .e-filterbar{
    display: none;
} */

.e-grid .e-rowcell {
    font-size: 9pt !important;
    font-weight: 400 !important;
}

.e-grid .e-gridpager {
    font-size: 9pt !important;
}

.e-dlg-header{
    width: 95% !important;
}

.e-dlg-header, .e-dlg-header *{
    font-size: 0.85rem !important;
}

.e-dlg-header .text-gray-500{
    color: rgb(107 114 128 / var(--tw-text-opacity));
}

.e-dlg-header svg{
    stroke: rgb(218, 221, 228) !important;
}

.dark input, .dark select{
    background-color: transparent;
    color: white;
}

.dark select option{
    background: white;
    color: black;
    margin: 10px;
}

.detalles-transaccion div:nth-child(even){
    background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.dark .detalles-transaccion div:nth-child(even){
    background-color: rgb(35 46 62 / var(--tw-bg-opacity));
}